import { createTheme } from "@mui/material/styles";


// カスタムテーマの定義
export const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(100, 192, 171,1)", // メインのプライマリカラー
      contrastText: 'rgb(255, 255, 255)',
    },
    secondary: {
      main: "rgb(255,255,255)", // セカンダリカラー
      contrastText: "rgb(0,0,0)", // セカンダリカラー
    },
    background: {
      default: "rgba(255, 255, 255, 0.95)", // デフォルトの背景色
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif", // テーマのデフォルトフォント
    h1: {
      fontSize: "2rem", // ヘッダー1のフォントサイズ
    },
    h2: {
        fontSize: "1.5rem", // ヘッダー2のフォントサイズ
        objectFit: 'contain',
        textAlign: 'center',
        border: 'unset',
        margin: 'auto',
        "& img": {
          width: "100%",
        },
    },
    h3:{
        fontSize: "1.2rem", // ヘッダー2のフォントサイズ
        margin:'unset',
    },
    subtitle1:{
        fontSize: "1.2rem", // ヘッダー2のフォントサイズ
        margin:'unset',
        fontWeight: '800',
    },
    caption:{
        fontSize: '1.0rem',
        fontWeight: '300',
        margin:'none',
    }
  },
  spacing: 8, // スペースの基本単位
  shape: {
    borderRadius: 10, // ボーダーの角丸のサイズ
  },
  breakpoints: {
    values: {
      xs: 0, // モバイルデバイス
      sm: 600, // タブレットデバイス
      md: 960, // 小さいデスクトップデバイス
      lg: 1280, // 大きいデスクトップデバイス
      xl: 1920, // エクストララージデスクトップデバイス
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: "64px", // ヘッダーの高さ
          backgroundColor: "#000000", // ヘッダーの背景色を黒色に設定
          position: "unset", // デフォルトはunset
          "@media (min-width: 600px)": {
            position: "sticky", // 600px以上の場合はfixed
          },
        },
      },
    },
    
  },
  // 他のテーマオプションも必要に応じて追加
});

export const themeFooter = {
  backgroundColor: "rgb(128, 128, 128)",
  color: theme.palette.primary.contrastText,
  gap: theme.spacing(3),
}

export const themeCopyright = {
  backgroundColor: "rgb(255,255,255)",
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(3),
}
