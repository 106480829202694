import { styled } from "@mui/material/styles";
import { themeFooter, themeCopyright } from "theme/Smaphon2Col";
import { Address } from "components/Address";
import { TriangleAnimation, LeftIcon } from "components/Link";
import mainLogoImage from "assets/images/main_logo.svg";
import iconMailImage from "assets/images/icon_mail.svg";
import iconMailWhiteImage from "assets/images/icon_mail_white.svg";
import iconLineBrandImage from "assets/images/icon_LINE_Brand.png";

export default function Main() {
  const Footer = styled("footer")(({ theme }) => ({
    ...themeFooter,
    width: "100%",
  }));

  const DivContent = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: "90%",
    maxWidth: "1080px",
    margin: "auto",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
      width: "65%",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplateColumns: "40% 40%",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    "& img.logo": {
      width: "100%",
      filter:
        "invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%)",
    },
    " .info": {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      " .address": {
        fontSize: "1.2rem",
        [theme.breakpoints.up("lg")]: {
          fontSize: "1.5rem",
        },
      },
    },
  }));

  const ContactContent = styled("div")(({ theme }) => ({
    "& h3": {
      margin: "unset",
      fontSize: "1.8rem",
      [theme.breakpoints.up("lg")]: {
        fontSize: "2.0rem",
      },
    },
  }));

  const Icon = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "left",
    "& img": {
      maxHeight: "65px",
      maxWidth: "65px",
    },
  }));

  const Copyright = styled("div")(({ theme }) => ({
    ...themeCopyright,
    width: "100%",
    boxSizing: "border-box",
    "& p": {
      textAlign: "center",
    },
  }));

  return (
    <Footer>
      <DivContent>
        <img className="logo" src={mainLogoImage} alt=""></img>
        <div className="info">
          <Address />
          <ContactContent>
            <Icon>
              <a href="mailto:mailto:miyoshi@genki-dokoro.jp">
                <img src={iconMailWhiteImage} alt=""></img>
              </a>
              <a href="https://lin.ee/Q0LpZEp" target="_blank" rel="noreferrer">
                <img src={iconLineBrandImage} alt=""></img>
              </a>
            </Icon>
          </ContactContent>
        </div>
      </DivContent>
      <Copyright>
        <p>Copyright © addonem LLC </p>
      </Copyright>
    </Footer>
  );
}
