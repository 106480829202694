import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import mainLogoImage from "assets/images/main_logo.svg";

export default function Main() {
  const Logo = styled("img")(({ theme }) => ({
    margin: "auto",
    position: "fixed",
    left: "50%",
    top: "50%",
    width: "100vw",
    height: "100vh",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.default,
    animationName: "opning",
    animationDuration: "4s",
    animationFillMode: "forwards",
    animationIterationCount: "1", // 初回のみ実行
    "@keyframes opning": {
      // アニメーションの定義
      "0%": {
        width: "100vw",
        height: "100vh",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.default,
        filter: "unset",
      },
      "25%": {
        width: "100vw",
        height: "100vh",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.default,
        filter: "unset",
      },
      "70%": {
        width: "100vw",
        height: "60px",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "unset",
        filter: "unset",
      },
      "75%": {
        width: "auto",
        height: "60px",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "unset",
        filter: "unset",
      },
      "100%": {
        width: "auto",
        height: "60px",
        left: "0",
        top: "0",
        transform: "translate(0%, 0%)",
        backgroundColor: "unset",
        filter:
          "invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%)",
      },
    },
  }));

  return (
    <AppBar component="header">
      <a href="/">
        <Logo src={mainLogoImage} alt="" />
      </a>
    </AppBar>
  );
}
