import { styled } from "@mui/material/styles";
import { theme } from "theme/Smaphon2Col";
import { TriangleAnimation, LeftIcon } from "components/Link";
import iconLineBrandImage from "assets/images/icon_LINE_Brand.png";
import iconMailImage from "assets/images/icon_mail.svg";
import iconMailWhiteImage from "assets/images/icon_mail_white.svg";

export default function Main() {
  const Aside = styled("aside")(({ theme }) => ({
    width: "100%",
    maxWidth: "450px",
    maxHeight: "60vh",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(5),
    padding: theme.spacing(3),
    position: "sticky",
    top: theme.spacing(11),
  }));

  const AnkerLinkes = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(4),
  }));

  const IconLinkes = styled(AnkerLinkes)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
  }));

  const LineStyle = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  };

  const MailStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  };

  return (
    <Aside id="side">
      <AnkerLinkes>
        <TriangleAnimation link="#Menu" label="料金メニュー" />
        <TriangleAnimation link="#Map" label="アクセス" />
      </AnkerLinkes>
      <IconLinkes>
        <LeftIcon
          link="https://lin.ee/Q0LpZEp"
          icon={iconLineBrandImage}
          label="LINE@"
          linkStyle={LineStyle}
        />
        <LeftIcon
          link="mailto:miyoshi@genki-dokoro.jp"
          icon={iconMailWhiteImage}
          label="メール"
          linkStyle={MailStyle}
        />
      </IconLinkes>
    </Aside>
  );
}
