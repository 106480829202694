import Button from "@mui/material/Button";
import { Link } from "react-router-dom"; // リンク先のURLがローカルの場合
import { theme } from "theme/Smaphon2Col";

const defStyle = {
  fontSize: "2.0rem",
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
};

interface AnotherTabLinkProps {
  link: string;
  label: string;
  style?: any;
}
export function AnotherTabButton({ link, label, style }: AnotherTabLinkProps) {
  const mergeStyle = Object.assign({}, defStyle, style);

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Button variant="contained" style={mergeStyle}>
        {label}
      </Button>
    </a>
  );
}

export function LinkButton({ link, label, style }: AnotherTabLinkProps) {
  const mergeStyle = Object.assign({}, defStyle, style);

  return (
    <a href={link}>
      <Button variant="contained" style={mergeStyle}>
        {label}
      </Button>
    </a>
  );
}
