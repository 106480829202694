import { styled } from "@mui/material/styles";

export function Address() {
  const Address = styled("div")(({ theme }) => ({
    ...theme.typography.caption,
    display: "flex",
    gap: theme.spacing(1),
    flexFlow: "column",
    "& p": {
      margin: "unset",
      "& span": {
        display: "block",
      },
    },
    "& p.post-num:before": {
      content: '"〒"',
    },
  }));
  return (
    <Address className="address">
      <p className="post-num">210-0023</p>
      <p>
        <span>神奈川県川崎市川崎区小川町13-21</span>
        <span>セコロ･T･ステージ 501</span>
      </p>
      <p>駐車場無し</p>
    </Address>
  );
}
